/*
应用中所有接口请求模块
    |- 每个接口返回的都是promise对象
 */

import {BASE, get, post, del} from "./ajax"

// 资源
export const getResources = (data) => get('/resources', data)

// 登录
export const isLogin = () => get('/admin/isLogin')
export const login = (data) => post('/admin/login', data)
export const loginOut = () => post('/admin/loginOut')

// 管理员注册
export const checkCode = (data) => post('/admin/checkCode', data)
export const checkEmail = (data) => post('/admin/checkEmail', data)
export const checkEmailCode = (data) => post('/admin/checkEmailCode', data)
export const register = (data) => post('/admin/register', data)

// 普通注册
export const checkEmailQuick = (data) => post('/admin/checkEmailQuick', data)
export const checkEmailCodeQuick = (data) => post('/admin/checkEmailCodeQuick', data)
export const registerQuick = (data) => post('/admin/registerQuick', data)

//oss
export const uploadFileToOSSUrl = () => BASE + '/oss/uploadFileToOSS';

// 菜单
export const getMenuList = () => get("/menu/menuList");

// 菜单配置
export const listBotMenu = (data) => get("/menu/list", data);
export const addBotMenu = (data) => post("/menu/add", data);
export const deleteBotMenu = (data) => post("/menu/delete", data);
export const updateMenuMapping = (data) => post("/menu/role", data);

// 角色配置
export const listRole = (data) => get("/role/list", data);

// twitter
export const getTweetByExternalId = (type, externalId) => get(`/pub/dynamic/${type}/${externalId}`);

// 应用列表
export const listRobot = (data) => get("/robot/list", data);
export const upBot = (data) => post(`/robot/up/${data.id}`);
export const downBot = (data) => post(`/robot/down/${data.id}`);
export const addBot = (data) => post("/robot/add", data);
export const deleteBot = (data) => del(`/robot/delete/${data.id}`);
export const editBot = (data) => post("/robot/edit", data);
export const getBot = (botId) => get(`/robot/${botId}`);

// 聊天记录
export const listMessageRecord = (data) => get("/messageRecord/list", data);

// 值班渠道
export const listBotSender = (data) => get("/sender/list", data);
export const updateBotSenderTask = (data) => post("/sender/update", data);
export const listBotSenderBotRobot = (data) => get("/sender/bot/list", data);
export const updateBotSenderBotRobotIndex = (data) => post("/sender/bot/update", data);

// 用户配置
export const listBotUser = (data) => get("/user/list", data);
export const changeStatus = (data) => post("/user/status", data);
export const bindUser = (data) => post("/user/bind", data);

// 随机对话配置
export const listRandomTalk = (data) => get("/randomTalk/list", data);
export const importRandomTalk = (data) => post("/randomTalk/import", data);

// Bot设置
export const listBotConfig = (data) => get("/config/list", data);
export const updateConfig = (data) => post("/config/update", data);

// 音乐
export const listMusic = (data) => get("/music/list", data);
export const syncMusic = (data) => post("/music/list/sync", data);
export const getPlayerData = (data) => get("/music/player", data);
export const getMusicEventUrl = (data) => BASE + `/pub/music/player/${data.botId}/event?eventToken=${data.eventToken}`;
export const stopPlayer = (data) => post("/music/player/stop", data);
export const startPlayer = (data) => post("/music/player/start", data);
export const lastPlayer = (data) => post("/music/player/last", data);
export const getLastMusic = (data) => get("/music/last", data);
export const getPrevMusic = (data) => get("/music/prev", data);
export const musicFileStream = (data) => `https://oss.tilitili.club/api/ktv/musicStream?musicId=${data.musicId}&userId=${data.userId}`;
export const searchMusic = (data) => get("/music/search", data);
export const addMusic = (data) => post("/music/add", data);
export const deleteMusic = (listId) => del(`/music/list/${listId}`);
export const updateMusicVolume = (data) => post(`/music/${data.musicId}/volume`, data);

// 绑定
export const getBindCode = (data) => get("/admin/bindCode", data);
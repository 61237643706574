import Control from "./Control";
import {useEffect, useState} from "react";
import {musicFileStream, getLastMusic, updateMusicVolume, getPrevMusic} from "../../../api";
import {If, isNotNull, isNull, log, nullFunc} from "../../../utils/HtmlUtils";
import {Howl} from 'howler'
import useDebounce from "../../../components/UseDebounce";

const getLocalVolume = () => {
    return parseFloat(isNull(localStorage.getItem('volume'), '50'));
}
const setLocalVolume = (volume) => {
    if (isNull(volume)) {
        localStorage.removeItem('volume');
    }
    localStorage.setItem('volume', volume.toString());
}
const getTheMusic = () => {
    if (localStorage.getItem('music') === null) {
        return null;
    }
    return JSON.parse(localStorage.getItem('music'));
}
const setTheMusic = (music) => {
    if (isNull(music)) {
        localStorage.removeItem('music');
    }
    localStorage.setItem('music', JSON.stringify(music));
}

const oldVolume = getLocalVolume();
if (oldVolume < 1 && oldVolume > 0) {
    localStorage.removeItem('volume');
}
if (isNull(getTheMusic())) {
    getLastMusic({listId:null, musicId:null, loopType:'random'}).then(theMusic => {
        console.log("init", theMusic);
        setTheMusic(theMusic)
    })
}

export default function LocalControl({playFlag, user}) {
    // const location = useLocation();
    const listId = playFlag?.listId;
    const theMusic = getTheMusic();

    const [isPlaying, setIsPlaying] = useState(false);
    const [loading, setLoading] = useState(false);
    const [volumeLoading, setVolumeLoading] = useState(false);
    // const [playerMusicId, setPlayerMusicId] = useState(null);
    const [playerMusicId, debounceLoading] = useDebounce(theMusic?.id, 1300);
    const [sound, setSound] = useState(null);
    // const [volume, setVolume] = useState(getLocalVolume());
    const [seek, setSeek] = useState(0);
    const [loopType, setLoopType] = useState(null);
    const [lastMusic, setLastMusic] = useState(null);
    const [isPlayEnd, setIsPlayEnd] = useState(false);

    const localVolume = getLocalVolume();
    const theVolume = theMusic?.volume;
    const useVolume = isNull(theVolume, localVolume);

    if (isPlayEnd && isNotNull(lastMusic)) {
        console.log("play last")
        setTheMusic(lastMusic);
        setLastMusic(null);
        setIsPlayEnd(false);
    }

    const reqLast = (listId, musicId, loopType) => {
        if (isNull(loopType)) {
            return;
        }
        setLoading(true)
        console.log("req last")
        getLastMusic({listId, musicId, loopType}).then(lastMusic => {
            setLastMusic(lastMusic);
        }).finally(() => setLoading(false));
    }

    useEffect(() => {
        // if (isNotNull(theMusic?.id)) {
            reqLast(listId, theMusic?.id, loopType)
        // }
    }, [listId, theMusic?.id, loopType])

    // useEffect(() => {
    // }, [lastMusic, isPlayEnd])

    useEffect(() => {
        if (isNull(playerMusicId)) {
            setIsPlayEnd(true);
            return;
        }
        const params = {
            src: [musicFileStream({musicId: playerMusicId, userId: user?.id})],
            preload: false,
            autoplay: true,
            format: ['mp3', 'aac'],
            volume: 0,
            pool: 1,
            onplay: () => setLoading(false),
            onend: () => {
                setIsPlayEnd(true);
            },
            onloaderror: () => {
                log.info("加载失败");
                setLoading(false);
                setIsPlaying(false);
                sound.unload();
            },
            onplayerror: () => {
                log.info("播放失败");
                setLoading(false);
                setIsPlaying(false);
                sound.unload();
            },
        }
        const sound = new Howl(Object.assign(params, {html5: true}));
        setSound(sound)
        return () => {
            sound.unload();
            setSound(null);
        }
    }, [user?.id, playerMusicId])


    // const reqLast = useCallback((musicId) => {
    //     setLoading(true)
    //     getLastMusic({listId, musicId, playLoop}).then(theMusic => {
    //         setTheMusic(lastMusic);
    //         setLastMusic(theMusic);
    //     }).finally(() => setLoading(false));
    // }, [listId]);

    const reqPrev = () => {
        setLoading(true)
        getPrevMusic().then(theMusic => {
            setTheMusic(theMusic);
        }).finally(() => setLoading(false));
    }

    // useEffect(() => {
    //     if (isNull(theMusic)) {
    //         setTheMusic(lastMusic)
    //         return;
    //     }
    //     const params = {
    //         src: [musicFileStream({musicId: theMusic.id, userId: user?.id})],
    //         preload: false,
    //         autoplay: true,
    //         format: ['mp3', 'aac'],
    //         volume: 0,
    //         pool: 1,
    //         onplay: () => setLoading(false),
    //         onend: () => {
    //             setTheMusic(null);
    //             reqLast(listId, null, loopType)
    //         },
    //         onloaderror: () => {
    //             log.info("加载失败");
    //             setLoading(false);
    //             setIsPlaying(false);
    //             sound.unload();
    //         },
    //         onplayerror: () => {
    //             log.info("播放失败");
    //             setLoading(false);
    //             setIsPlaying(false);
    //             sound.unload();
    //         },
    //     }
    //     // console.log("isPhone", os.isPhone);
    //     // const sound = If (os.isPhone)
    //     //     .then(() => new Howl(Object.assign(params, {xhr: {withCredentials: true}})))
    //     //     .else(() => new Howl(Object.assign(params, {html5: true})));
    //     const sound = new Howl(Object.assign(params, {html5: true}));
    //     setSound(sound)
    //     return () => {
    //         sound.unload();
    //         setSound(null);
    //     }
    // }, [user?.id, theMusic, reqLast, lastMusic])

    useEffect(() => {
        if (isNotNull(sound)) {
            sound.volume(useVolume / 200);
        }
    }, [sound, useVolume])

    useEffect(() => {
        if (isNull(sound)) {
            return;
        }
        if (isPlaying) {
            setLoading(true)
            if (sound.state() === 'unloaded') {
                sound.load();
                // sound.play();
            } else {
                sound.play();
            }
        } else {
            if (sound.state() === 'loaded') {
                sound.pause();
            }
        }
    }, [sound, isPlaying])

    useEffect(() => {
        // 每秒钟读取一次音频当前的进度
        const intervalId = setInterval(() => {
            setSeek(Math.round(sound?.seek() || 0));
        }, 1000);

        return () => {
            clearInterval(intervalId);
        }
    }, [sound])

    const onStop = () => {
        setIsPlaying(false);
    }

    const onStart = () => {
        setIsPlaying(true);
    }

    const onLast = () => {
        setIsPlayEnd(true);
    }

    const onPrev = () => {
        reqPrev();
    }

    const onShowQueue = () => {
    }

    const onSeekChange = seek => {
        sound.seek(seek);
        setSeek(seek);
    }

    const onVolumeChange = volume => {
        sound.volume(volume / 200);
    }

    const onVolumeChangeComplete = volume => {
        if (isNull(theMusic?.volume)) {
            setLocalVolume(volume);
        } else {
            setVolumeLoading(true);
            updateMusicVolume({musicId: theMusic?.id, volume}).then(() => {
                theMusic.volume = volume;
            }).finally(() => setVolumeLoading(false));
        }
    }

    const onCustomChange = checked => {
        if (checked) {
            setVolumeLoading(true);
            updateMusicVolume({musicId: theMusic?.id, volume: useVolume}).then(() => {
                theMusic.volume = useVolume;
            }).finally(() => setVolumeLoading(false));
        } else {
            setVolumeLoading(true);
            updateMusicVolume({musicId: theMusic?.id}).then(() => {
                theMusic.volume = null;
            }).finally(() => setVolumeLoading(false));
        }
    }

    return <Control theMusic={theMusic}
                    isPlaying={isPlaying}
                    loading={loading || debounceLoading}
                    onStart={onStart}
                    onStop={onStop}
                    onLast={onLast}
                    onShowQueue={onShowQueue}
                    onPrev={onPrev}
                    onLoopTypeChange={setLoopType}
                    volume={{
                        defaultValue: useVolume,
                        onChange: onVolumeChange,
                        onChangeComplete: onVolumeChangeComplete,
                        useCustomVolume: isNotNull(theMusic?.volume),
                        onCustomChange,
                        volumeLoading
                    }}
                    seek={If (isNotNull(sound)).then(() => ({
                        max: Math.ceil(sound.duration()),
                        value: seek,
                        onChange: onSeekChange,
                        formatter: seek => {
                            const minutes = Math.floor(seek / 60) || 0;
                            const seconds = (seek - minutes * 60) || 0;

                            return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
                        },
                        tooltip: {open: false},
                    })).else(nullFunc)}
    />
}